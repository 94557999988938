import React from 'react';

export const Chevron = ({
	orientation = 'down',
	strokeColor = '#444',
	height = '8',
	width = '16',
}) => {
	let transform = '';
	switch (orientation) {
		case 'down':
			break;
		case 'up':
			transform = `transform: rotate(180deg);`;
			break;
		case 'right':
			transform = `transform: rotate(-90deg);`;
			break;
		case 'left':
			transform = `transform: rotate(90deg);`;
			break;
		default:
			break;
	}

	return (
		<svg
			css={transform}
			width={width}
			height={height}
			viewBox='0 0 16 8'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className='chevron'
		>
			<path
				d='M1.33341 0.666686L8.00008 7.33335L14.6667 0.666687'
				stroke={strokeColor}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const ChevronBack = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width='20'
		height='20'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M14.1666 1.66671L5.83329 10L14.1666 18.3334'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const ChevronBordered = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width='40'
		height='40'
		viewBox='0 0 40 40'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<circle cx='20' cy='20' r='19.5' fill='white' stroke='#E2E2E2' />
		<path
			d='M16.6665 28.3333L24.9998 20L16.6665 11.6666'
			stroke='#444444'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const Info = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='20'
		height='20'
		fill='none'
		viewBox='0 0 20 20'
		{...props}
		aria-hidden='true'
	>
		<path
			d='M10.0002 1.66659C5.39779 1.66658 1.66683 5.39754 1.66683 9.99992C1.66683 14.6023 5.39779 18.3333 10.0002 18.3333C14.6025 18.3333 18.3335 14.6023 18.3335 9.99992C18.3335 5.39755 14.6025 1.66659 10.0002 1.66659Z'
			stroke={props.color || '#A4563B'}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10 13.3333L10 9.99992'
			stroke={props.color || '#A4563B'}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10 6.66675L9.99167 6.66675'
			stroke={props.color || '#A4563B'}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const PlaySvg = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 0 24 24'
	>
		<path d='M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18a1 1 0 000-1.69L9.54 5.98A.998.998 0 008 6.82z'></path>
	</svg>
);

export const ImageSvg = (props: Record<string, any>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		fill='none'
		viewBox='0 0 16 16'
		{...props}
	>
		<path
			stroke='#444'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M12.667 2H3.333C2.597 2 2 2.597 2 3.333v9.334C2 13.403 2.597 14 3.333 14h9.334c.736 0 1.333-.597 1.333-1.333V3.333C14 2.597 13.403 2 12.667 2z'
		></path>
		<path
			stroke='#444'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M5.667 6.667a1 1 0 100-2 1 1 0 000 2zM14 10l-3.333-3.333L3.333 14'
		></path>
	</svg>
);
